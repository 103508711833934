var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "relative flex w-full flex-col space-y-4",
      attrs: { id: "availableSlotsCard" },
    },
    [
      _c("div", { staticClass: "flex items-center justify-between" }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm.formatInTimeZone(
                  new Date(),
                  _vm.payload.timezone,
                  "eee dd MMMM"
                )
              )
          ),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.formatInTimeZone(
                  new Date(),
                  _vm.payload.timezone,
                  "HH:mm:ss"
                )
              )
            ),
          ]),
        ]),
        _c("p", [_vm._v(_vm._s(_vm.payload.timezone))]),
      ]),
      _c("spinner-overlay", {
        attrs: {
          loading: _vm.uiState === "loading",
          color: "transparent",
          size: "xxl",
        },
      }),
      _c(
        "div",
        {
          staticClass: "flex w-full",
          class: {
            "flex-col space-y-4": _vm.display === "column",
            "space-x-2": _vm.display === "row",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex flex-col space-y-4",
              class: { "w-2/5": _vm.display === "row" },
            },
            [
              _vm.useDate
                ? _c(
                    "div",
                    { staticClass: "space-y-2" },
                    [
                      _vm.showLabels
                        ? _c("base-label", { attrs: { for: "date" } }, [
                            _vm._v(" Date "),
                          ])
                        : _vm._e(),
                      _c("Datepicker", {
                        staticClass:
                          "border-gray-light rounded-lg border font-medium",
                        attrs: {
                          id: "date",
                          "monday-first": true,
                          "disabled-dates": _vm.disabledDates,
                        },
                        on: {
                          selected: function ($event) {
                            ;(_vm.selectedDate = $event), _vm.fetchTimes()
                          },
                        },
                        model: {
                          value: _vm.selectedDate,
                          callback: function ($$v) {
                            _vm.selectedDate = $$v
                          },
                          expression: "selectedDate",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.services.length
                ? _c(
                    "div",
                    { staticClass: "space-y-2" },
                    [
                      _vm.showLabels
                        ? _c("base-label", { attrs: { for: "service" } }, [
                            _vm._v(" Service "),
                          ])
                        : _vm._e(),
                      _vm.services.length
                        ? _c("v-select", {
                            attrs: {
                              id: "service",
                              loading: !_vm.services.length,
                              clearable: false,
                              options: _vm.services,
                              value: _vm.payload.serviceId,
                              label: "name",
                              reduce: function (opt) {
                                return opt.id
                              },
                            },
                            on: {
                              input: function ($event) {
                                ;(_vm.payload.serviceId = $event),
                                  _vm.fetchTimes()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "space-y-2" },
                [
                  _vm.showLabels
                    ? _c("base-label", { attrs: { for: "animalType" } }, [
                        _vm._v(" Animal type "),
                      ])
                    : _vm._e(),
                  _c("v-select", {
                    attrs: {
                      id: "animalType",
                      clearable: false,
                      options: _vm.animalTypes,
                      value: _vm.payload.animalTypeId,
                      label: "name",
                      disabled: !!_vm.selectedAnimalType,
                      reduce: function (opt) {
                        return opt.id
                      },
                    },
                    on: {
                      input: function ($event) {
                        ;(_vm.payload.animalTypeId = $event), _vm.fetchTimes()
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.usStates.length &&
              _vm.booking &&
              _vm.country.id === _vm.US_COUNTRY_ID
                ? _c(
                    "div",
                    { staticClass: "space-y-2" },
                    [
                      _vm.showLabels
                        ? _c("base-label", { attrs: { for: "usState" } }, [
                            _vm._v(" Licensed in state (US) "),
                          ])
                        : _vm._e(),
                      _c("v-select", {
                        attrs: {
                          id: "usState",
                          options: !_vm.prescriptionSelected
                            ? _vm.usStates
                            : _vm.usStatesPrescriptionsEnabled,
                          label: "state",
                          value: _vm.selectedState,
                          disabled: _vm.stateDisabled,
                          placeholder: !_vm.prescriptionSelected
                            ? "All states"
                            : "Prescriptions enabled states",
                        },
                        on: {
                          input: function ($event) {
                            ;(_vm.selectedState = $event), _vm.fetchTimes()
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "space-y-2" },
                [
                  _vm.showLabels
                    ? _c("base-label", { attrs: { for: "timezone" } }, [
                        _vm._v(" Timezone "),
                      ])
                    : _vm._e(),
                  _c("v-select", {
                    attrs: {
                      id: "timezone",
                      clearable: false,
                      options: _vm.timeZones,
                      value: _vm.payload.timezone,
                      label: "name",
                    },
                    on: {
                      input: function ($event) {
                        ;(_vm.payload.timezone = $event), _vm.fetchTimes()
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "label",
                {
                  staticClass: "inline-flex items-center",
                  attrs: { for: "withPrice" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.payload.withPrice,
                        expression: "payload.withPrice",
                      },
                    ],
                    attrs: {
                      id: "withPrice",
                      type: "checkbox",
                      "true-value": "1",
                      "false-value": "0",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.payload.withPrice)
                        ? _vm._i(_vm.payload.withPrice, null) > -1
                        : _vm._q(_vm.payload.withPrice, "1"),
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.payload.withPrice,
                          $$el = $event.target,
                          $$c = $$el.checked ? "1" : "0"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.payload,
                                "withPrice",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.payload,
                                "withPrice",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.payload, "withPrice", $$c)
                        }
                      },
                    },
                  }),
                  _c("span", { staticClass: "ml-2 text-sm" }, [
                    _vm._v("Get price information"),
                  ]),
                ]
              ),
              _c(
                "base-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    color: "primary",
                    disabled: _vm.uiState === "loading",
                  },
                  on: { click: _vm.fetchTimes },
                },
                [_vm._v(" Refresh ")]
              ),
              _c(
                "div",
                { staticClass: "mx-auto block" },
                [
                  _vm.pagination.data && _vm.pagination.data.length
                    ? _c("PaginationButtons", {
                        attrs: { "pagination-list": _vm.pagination },
                        on: {
                          next: _vm.fetchTimesByUrl,
                          previous: _vm.fetchTimesByUrl,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "flex flex-1 flex-col space-y-4 overflow-hidden",
              staticStyle: { "max-height": "75vh" },
            },
            [
              _vm.uiState === "error"
                ? _c("p", { staticClass: "text-red-700" }, [
                    _vm._v(" " + _vm._s(_vm.errorMsg) + " "),
                  ])
                : _vm._e(),
              !_vm.slots.length && _vm.uiState === "idle"
                ? _c(
                    "p",
                    {
                      staticClass:
                        "inline-flex items-center space-x-3 rounded-lg border bg-beigeLight p-4 shadow",
                    },
                    [
                      _c("fv-icon", {
                        staticClass: "text-primary-dark",
                        attrs: { icon: "warning" },
                      }),
                      _c("span", [
                        _vm._v(
                          "Couldn't find any slots matching the criteria."
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.prescriptionMessage
                ? _c(
                    "p",
                    {
                      staticClass:
                        "inline-flex items-center space-x-3 rounded-lg border bg-beigeLight p-4 shadow",
                    },
                    [
                      _c("fv-icon", {
                        staticClass: "text-primary-dark",
                        attrs: { icon: "warning" },
                      }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.prescriptionMessage) + " "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.slots.length && !_vm.prescriptionMessage
                ? _c(
                    "ul",
                    {
                      staticClass:
                        "flex flex-col space-y-1 overflow-auto rounded-t rounded-b border-t border-b",
                    },
                    _vm._l(_vm.slots, function (slot) {
                      return _c(
                        "li",
                        {
                          key: slot.id,
                          staticClass:
                            "flex justify-between space-x-4 rounded border bg-white py-2 pl-2 pr-4 hover:bg-gray-50",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex flex-col space-y-1" },
                            [
                              _c("p", [
                                _c(
                                  "span",
                                  { staticClass: "mr-2 text-lg font-semibold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatInTimeZone(
                                            new Date(slot.dateTime.dateTime),
                                            _vm.payload.timezone,
                                            "HH:mm z"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatInTimeZone(
                                          new Date(slot.dateTime.dateTime),
                                          _vm.payload.timezone,
                                          "EEEE"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("p", [_vm._v(_vm._s(slot.user.display_name))]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "flex items-center pr-3" },
                            [
                              slot.price
                                ? _c("span", { staticClass: "pr-5" }, [
                                    _vm._v(
                                      _vm._s(slot.price.amount) +
                                        " " +
                                        _vm._s(slot.price.currency)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.booking
                                ? _c(
                                    "text-button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setSelectedSlot(slot)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-semibold text-primary-dark",
                                        },
                                        [_vm._v(" Select ")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }