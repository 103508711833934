<template>
  <div id="availableSlotsCard" class="relative flex w-full flex-col space-y-4">
    <div class="flex items-center justify-between">
      <p>
        {{ formatInTimeZone(new Date(), payload.timezone, 'eee dd MMMM')
        }}<span>{{
          formatInTimeZone(new Date(), payload.timezone, 'HH:mm:ss')
        }}</span>
      </p>
      <p>{{ payload.timezone }}</p>
    </div>

    <spinner-overlay
      :loading="uiState === 'loading'"
      color="transparent"
      size="xxl"
    />
    <div
      class="flex w-full"
      :class="{
        'flex-col space-y-4': display === 'column',
        'space-x-2': display === 'row',
      }"
    >
      <div
        class="flex flex-col space-y-4"
        :class="{ 'w-2/5': display === 'row' }"
      >
        <div v-if="useDate" class="space-y-2">
          <base-label v-if="showLabels" for="date"> Date </base-label>
          <Datepicker
            id="date"
            v-model="selectedDate"
            class="border-gray-light rounded-lg border font-medium"
            :monday-first="true"
            :disabled-dates="disabledDates"
            @selected="(selectedDate = $event), fetchTimes()"
          />
        </div>

        <div v-if="services.length" class="space-y-2">
          <base-label v-if="showLabels" for="service"> Service </base-label>
          <v-select
            v-if="services.length"
            id="service"
            :loading="!services.length"
            :clearable="false"
            :options="services"
            :value="payload.serviceId"
            label="name"
            :reduce="opt => opt.id"
            @input="(payload.serviceId = $event), fetchTimes()"
          />
        </div>
        <div class="space-y-2">
          <base-label v-if="showLabels" for="animalType">
            Animal type
          </base-label>
          <v-select
            id="animalType"
            :clearable="false"
            :options="animalTypes"
            :value="payload.animalTypeId"
            label="name"
            :disabled="!!selectedAnimalType"
            :reduce="opt => opt.id"
            @input="(payload.animalTypeId = $event), fetchTimes()"
          />
        </div>
        <div
          v-if="usStates.length && booking && country.id === US_COUNTRY_ID"
          class="space-y-2"
        >
          <base-label v-if="showLabels" for="usState">
            Licensed in state (US)
          </base-label>
          <v-select
            id="usState"
            :options="
              !prescriptionSelected ? usStates : usStatesPrescriptionsEnabled
            "
            label="state"
            :value="selectedState"
            :disabled="stateDisabled"
            :placeholder="
              !prescriptionSelected
                ? 'All states'
                : 'Prescriptions enabled states'
            "
            @input="(selectedState = $event), fetchTimes()"
          />
        </div>
        <!-- <v-select
          v-if="booking"
          :options="countries"
          label="name"
          :value="selectedCountry"
          :clearable="false"
          @input="changeCountry($event)"
        /> -->
        <div class="space-y-2">
          <base-label v-if="showLabels" for="timezone"> Timezone </base-label>
          <v-select
            id="timezone"
            :clearable="false"
            :options="timeZones"
            :value="payload.timezone"
            label="name"
            @input="(payload.timezone = $event), fetchTimes()"
          />
        </div>
        <label for="withPrice" class="inline-flex items-center">
          <input
            id="withPrice"
            v-model="payload.withPrice"
            type="checkbox"
            true-value="1"
            false-value="0"
          />
          <span class="ml-2 text-sm">Get price information</span>
        </label>

        <base-button
          color="primary"
          class="mt-4"
          :disabled="uiState === 'loading'"
          @click="fetchTimes"
        >
          Refresh
        </base-button>

        <div class="mx-auto block">
          <PaginationButtons
            v-if="pagination.data && pagination.data.length"
            :pagination-list="pagination"
            @next="fetchTimesByUrl"
            @previous="fetchTimesByUrl"
          />
        </div>
      </div>

      <div
        class="flex flex-1 flex-col space-y-4 overflow-hidden"
        style="max-height: 75vh"
      >
        <p v-if="uiState === 'error'" class="text-red-700">
          {{ errorMsg }}
        </p>
        <p
          v-if="!slots.length && uiState === 'idle'"
          class="inline-flex items-center space-x-3 rounded-lg border bg-beigeLight p-4 shadow"
        >
          <fv-icon icon="warning" class="text-primary-dark" />
          <span>Couldn't find any slots matching the criteria.</span>
        </p>
        <p
          v-if="prescriptionMessage"
          class="inline-flex items-center space-x-3 rounded-lg border bg-beigeLight p-4 shadow"
        >
          <fv-icon icon="warning" class="text-primary-dark" />
          <span>{{ prescriptionMessage }} </span>
        </p>
        <ul
          v-if="slots.length && !prescriptionMessage"
          class="flex flex-col space-y-1 overflow-auto rounded-t rounded-b border-t border-b"
        >
          <li
            v-for="slot in slots"
            :key="slot.id"
            class="flex justify-between space-x-4 rounded border bg-white py-2 pl-2 pr-4 hover:bg-gray-50"
          >
            <div class="flex flex-col space-y-1">
              <p>
                <span class="mr-2 text-lg font-semibold">
                  {{
                    formatInTimeZone(
                      new Date(slot.dateTime.dateTime),
                      payload.timezone,
                      'HH:mm z'
                    )
                  }}
                </span>
                <span class="mr-2">
                  {{
                    formatInTimeZone(
                      new Date(slot.dateTime.dateTime),
                      payload.timezone,
                      'EEEE'
                    )
                  }}
                </span>
              </p>
              <p>{{ slot.user.display_name }}</p>
            </div>

            <div class="flex items-center pr-3">
              <span v-if="slot.price" class="pr-5"
                >{{ slot.price.amount }} {{ slot.price.currency }}</span
              >
              <text-button
                v-if="booking"
                type="button"
                @click="setSelectedSlot(slot)"
              >
                <span class="font-semibold text-primary-dark"> Select </span>
              </text-button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { __DEV__ } from '@/constants';
import { US_COUNTRY_ID } from '@/config/countries';
import Datepicker from 'vuejs-datepicker';
import PaginationButtons from '@/UI/PaginationButtons';
import { ScheduleApi } from '@/api';
import axios from '@/plugins/axios';
import { getTimezonesForCountry } from '@/utils/helpers/timezone-helpers';
import { format, addDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export default defineComponent({
  components: {
    PaginationButtons,
    Datepicker,
  },
  props: {
    useDate: {
      type: Boolean,
      default: false,
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    display: {
      type: String,
      default: 'column',
    },
    country: {
      type: Object,
      required: true,
    },
    usState: {
      type: Object || null,
      default: null,
    },
    timezone: {
      type: String,
      default: 'Europe/London',
    },
    animalTypes: {
      type: Array,
      default: () => [],
    },
    usStates: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: () => [],
    },
    booking: {
      type: Boolean,
      default: false,
    },
    services: {
      type: Array,
      default: () => [],
    },
    selectedAnimalType: {
      type: Number,
      required: false,
    },
    selectedService: {
      type: Number,
      required: false,
    },
    caseType: {
      type: [Object || null],
      default: null,
    },
  },

  data() {
    return {
      formatInTimeZone,
      uiState: 'idle',
      payload: {
        serviceId: this.$props.selectedService ?? 1,
        animalTypeId: this.$props.selectedAnimalType ?? 1,
        timezone: this.country.timeZone ?? this.timezone,
        usStates: [],
        withPrice: 0,
      },
      selectedCountry: this.country,
      selectedDate: format(new Date(), 'yyyy-MM-dd'),
      selectedState: null,

      errorMsg: null,
      defaultTimeZones: [
        'America/New_York',
        'America/Los_Angeles',
        'America/Chicago',
        'Europe/Stockholm',
        'Europe/London',
        'Europe/Berlin',
        'Europe/Oslo',
        'Europe/Helsinki',
        'Europe/Copenhagen',
      ],
      timeZones: [],
      timeOfRequest: format(new Date(), 'yyyy-MM-dd'),

      pagination: {
        data: [],
      },
      US_COUNTRY_ID,
      error: '',
    };
  },

  computed: {
    isDev() {
      return __DEV__;
    },
    isUS() {
      return this.selectedCountry?.id === US_COUNTRY_ID;
    },
    clientDate: {
      get() {
        return formatInTimeZone(
          new Date(this.timeOfRequest),
          this.payload.timezone,
          'yyyy-MM-dd'
        );
      },
      set(val) {
        this.timeOfRequest = val;
      },
    },
    slots() {
      return this.pagination.data || [];
    },
    disabledDates() {
      return {
        to: new Date(),
      };
    },
    hasSelectedToday() {
      return (
        format(new Date(), 'yyyy-MM-dd') ===
        format(new Date(this.selectedDate), 'yyyy-MM-dd')
      );
    },
    stateDisabled() {
      return (
        this.caseType?.prescriptionCheck &&
        this.selectedState &&
        !this.selectedState?.prescriptions_enabled
      );
    },
    prescriptionSelected() {
      return this.caseType?.key === 'prescriptions';
    },
    usStatesPrescriptionsEnabled() {
      return this.usStates.filter(state => state.prescriptions_enabled);
    },
    prescriptionMessage() {
      if (!this.prescriptionSelected) {
        return '';
      }

      if (this.selectedState && !this.selectedState.prescriptions_enabled) {
        return "Prescriptions are not available in customer's state";
      }

      if (!this.selectedState) {
        return 'Ask customer for state';
      }

      return '';
    },
  },
  mounted() {
    this.setUsState();
    this.fetchTimes();
    this.setTimeZones();

    if (this.country.country_id) {
      this.selectedCountry = this.countries.find(
        cc => cc.id === this.country.country_id
      );
    }
  },

  methods: {
    async fetchTimes() {
      this.uiState = 'loading';
      this.errorMsg = null;

      try {
        if (this.hasSelectedToday) {
          this.clientDate = format(new Date(), 'yyyy-MM-dd');
        } else {
          this.clientDate = format(new Date(this.selectedDate), 'yyyy-MM-dd');
        }
        const toDate = addDays(new Date(this.clientDate), 1);
        const queryParams = {
          ...this.payload,
          countryId: this.selectedCountry.id,
          fromDate: format(new Date(this.clientDate), 'yyyy-MM-dd'),
          fromTime: format(new Date(this.clientDate), 'HH:mm'),
          toDate: format(new Date(toDate), 'yyyy-MM-dd'),
          random: true,
          paginate: 16,
          ignoreMargin: 1,
        };
        if (this.selectedState) {
          queryParams.usStates = [this.selectedState.id];
        }

        const { data } = await ScheduleApi.fetchAvailableSlots(queryParams);
        this.pagination = data;
        // this.slots = data.data;
        this.uiState = 'idle';

        return data;
      } catch (error) {
        this.uiState = 'error';
        this.pagination = { data: [] };
        this.errorMsg = error.message;
        return Promise.reject(error);
      }
    },
    async fetchTimesByUrl(url) {
      this.uiState = 'loading';
      try {
        const { data } = await axios.get(url);
        this.pagination = data;
        this.uiState = 'idle';
        return data;
      } catch (error) {
        this.uiState = 'idle';
        this.pagination = { data: [] };
        this.errorMsg = error.message;
        return Promise.reject(error);
      }
    },
    setSelectedSlot(slot) {
      this.$emit('selected', slot);
      this.$emit('timezone', this.payload.timezone);
    },
    setTimeZones() {
      this.timeZones = this.booking
        ? getTimezonesForCountry(this.selectedCountry.iso_3166_2)
        : this.defaultTimeZones;
    },
    changeCountry(newCountry) {
      this.selectedCountry = newCountry;
      this.setTimeZones(this.selectedCountry.iso_3166_2);
      const [timeZone] = this.timeZones;
      this.payload.timezone = timeZone;
      this.fetchTimes();
    },
    handleWithPriceInput(event) {
      console.log(event.target.value);
    },
    setUsState() {
      if (!this.prescriptionSelected) {
        this.selectedState = null;
        return;
      }
      if (this.usState?.id) {
        this.selectedState = this.usStates.find(
          cc => cc.id === this.usState.id
        );
      }
    },
  },
});
</script>
